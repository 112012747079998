import Bugsnag from '@bugsnag/js';
import { Channel, type Customer, type User } from '@prisma/client';
import type { MetaFunction } from '@remix-run/node';
import { type LinksFunction, redirect } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useSearchParams } from '@remix-run/react';
import type { ErrorBoundaryComponent } from '@remix-run/react/dist/routeModules';
import { useEffect, useMemo } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { typedjson, useTypedLoaderData } from 'remix-typedjson';
import invariant from 'tiny-invariant';

import { ErrorBoundaryDisplay } from '~/components/ErrorBoundaryDisplay';
import { SessionExpired } from '~/components/SessionExpired';
import { ToastNotification } from '~/components/ui-components/ToastNotification';
import type { MyLoaderArgs } from '~/container';
import { env } from '~/env';
import type { FlashMessage } from '~/services/session';
import { isSiteAdmin } from '~/utils/auth-z';
import { setDatadogMeta } from '~/utils/datadog';
import type { AppHandle } from '~/utils/hooks/use-app-handle';
import { useAssertTimezoneIsSet } from '~/utils/hooks/use-assert-timezone-is-set';
import { useGoogleAnalytics } from '~/utils/hooks/use-google-analytics';
import { useKonamiCode } from '~/utils/hooks/use-konami-code';
import { mapStyleLinks } from '~/utils/map';
import { ClientOnly } from '~/utils/remix-utils/client-only';
import { relativeTime } from '~/utils/time';
import { getCurrentVersion } from '~/utils/versioning';

import { browserLogger } from './browser-logger';
import { InactivityTimeout } from './components/InactivityTimer';
import stylesheet from './styles/style.css';
import { type RootContext } from './utils/hooks/use-root-context';
import { $path } from './utils/route-helpers';

export const links: LinksFunction = () => {
  return [
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=IM+Fell+English:ital@0;1&family=IM+Fell+English&family=Red+Hat+Text:wght@300;350;400;450;500;550;600;650;700&&family=Roboto:wght@400;500&family=Oswald:wght@200;400&display=swap'
  },
  { rel: 'stylesheet', href: stylesheet },
  {
    rel: 'icon',
    type: 'image/x-icon',
    href: '/favicon.png'
  },
  ...mapStyleLinks()];

};

export const DEFAULT_TITLE = 'Indigov';
export const meta: MetaFunction = () => [{ title: DEFAULT_TITLE }];

export type CurrentCustomer = Pick<
  Customer,
  'id' | 'name' | 'geoJson' | 'formalNameMergeTagFallback' | 'informalNameMergeTagFallback' | 'supersetDashboardId'>;

export type CurrentUser = Pick<User, 'id' | 'email' | 'name'>;

export const loader = async ({ context: { cnt }, request }: MyLoaderArgs) => {
  const [auth, currentUser, impersonatedUser, customer, session, translations] = await Promise.all([
  cnt.services.authN.getAuth(request),
  cnt.services.authN.getUser(),
  cnt.services.authN.getImpersonatedUser(),
  cnt.services.authN.getCustomer(),
  cnt.services.session.storage.getSession(request.headers.get('Cookie')),
  cnt.prisma.locale.findFirst({ select: { updatedAt: true }, orderBy: { updatedAt: 'desc' } })]
  );

  const featureFlags = await cnt.services.featureFlag.getAll({ customer: customer ?? undefined });

  const hasMultipleAccounts = currentUser ? (await cnt.services.authZ.getCustomers(currentUser)).length > 1 : false;

  const message = session.get('message') || null;

  // Used for displaying last deploy time in admin
  const uptime = env.uptime;

  try {
    // this will throw an error if the request is not authorized
    // we will want to call this before every loader and action
    await cnt.services.authZ.authorizeRequest(request, currentUser, customer);
  } catch (e) {
    browserLogger().warn('Unauthorized request', { error: e });
    throw redirect($path('/auth/signin'), {
      headers: {
        'Set-Cookie': await cnt.services.session.flashMessage(null, `You are not authorized to view that page.`)
      }
    });
  }

  const timezone = await cnt.services.sessionTimeZone.get();

  const currentUserPermissions = currentUser && customer ? await cnt.services.authZ.getPermissionsForUser(currentUser, customer) : [];

  const impersonationUntilString = session.get('impersonationUntil');
  const impersonationUntil = impersonationUntilString ? new Date(impersonationUntilString) : null;
  const impersonatedUserPermissions =
  impersonatedUser && customer ? await cnt.services.authZ.getPermissionsForUser(impersonatedUser, customer) : [];

  const emailUsage = customer ? await cnt.services.campaignMetrics.usageMetrics({ customer, channel: Channel.Email }) : undefined;

  const clientEnv: ClientEnv = {
    pod: env.pod,
    version: getCurrentVersion(),
    enableRum: env.enableRum,
    enableBugsnag: env.enableBugsnag,
    translationsUpdatedAt: translations?.updatedAt?.toISOString(),
    googleAnalyticsTrackingId: env.googleAnalyticsTrackingId
  };

  const outletContext: RootContext = {
    auth,
    message,
    uptime,
    currentUser,
    currentUserPermissions,
    impersonatedUser,
    impersonationUntil,
    impersonatedUserPermissions,
    customer,
    isSiteAdmin: currentUser ? isSiteAdmin(currentUser) : false,
    timezone,
    emailUsage,
    hasMultipleAccounts,
    clientEnv,
    featureFlags
  };

  return typedjson(outletContext, {
    headers: {
      'Set-Cookie': await cnt.services.session.storage.commitSession(session)
    }
  });
};

export const handle: AppHandle = {
  i18n: 'common'
};

function GlobalMeta() {
  return (
    <>
      <meta charSet='utf-8' />
      <meta name='viewport' content='width=device-width,initial-scale=1' />
      <meta name='robots' content='noindex' />
    </>);

}

export default function App() {
  const outletContext = useTypedLoaderData<typeof loader>();

  useAssertTimezoneIsSet(outletContext.timezone);

  useGoogleAnalytics(outletContext.clientEnv.googleAnalyticsTrackingId, outletContext.customer);

  useKonamiCode();

  useUpdatedUserTrackingMetadata(outletContext);

  let message: FlashMessage = outletContext?.message;

  // Allow easily sending/debugging a flash message with a query param `msg=Hello`
  const [searchParams] = useSearchParams();
  let msg = searchParams.get('msg');
  let msgType = searchParams.get('msg-type');
  if (msg) {
    message = {
      type: (msgType as FlashMessage['type']) || 'info',
      message: msg,
      time: Date.now()
    };
  }

  useEffect(() => {
    if (message) {
      toast.custom(
        (t) => {
          invariant(message, 'message required');
          return <ToastNotification t={t} message={message} />;
        },
        {
          duration: message?.job ? Infinity : undefined,
          position: 'bottom-center'
        }
      );
    }
  }, [message]);

  return (
    <html lang='en'>
      <head>
        <GlobalMeta />
        <Meta />
        <Links />
      </head>
      <body className='bg-accent-10'>
        <Outlet context={outletContext} />
        <Toaster position='bottom-right' />
        <ScrollRestoration />
        {/* This is the suggested way of exposing environment variables in Remix: https://remix.run/docs/en/v1/guides/envvars#browser-environment-variables */}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(outletContext.clientEnv)}`
          }} />

        <Scripts />
        <LiveReload />

        {!!outletContext.auth && !!outletContext.customer && <SessionExpired loginDate={outletContext.auth.loginDate} />}
        {!!outletContext.auth && <InactivityTimeout />}
        {outletContext.isSiteAdmin && <AdminTools context={outletContext} />}
      </body>
    </html>);

}

const AdminTools: React.FC<{context: RootContext;}> = ({ context }) => {
  return (
    <ClientOnly>
      {() =>
      <div className='fixed bottom-0.5 left-0.5 z-50 flex space-x-2 rounded bg-white px-1 py-0.5 text-xs opacity-25 transition-all hover:opacity-100'>
          <AppVersion version={context.clientEnv.version} uptime={context.uptime} />
        </div>}

    </ClientOnly>);

};

const AppVersion = ({ version, uptime }: {version: string;uptime: number;}) => {
  return (
    <div>
      <a href={`https://github.com/indigov-us/revere/commit/${version}`} target='_blank' rel='noreferrer'>
        v{version}
      </a>
      <span> deployed {relativeTime(uptime)}</span>
    </div>);

};

const useUpdatedUserTrackingMetadata = (outletContext: RootContext) => {
  useMemo(() => {
    if (outletContext.clientEnv.enableRum && typeof window !== 'undefined') {
      setDatadogMeta(outletContext);
    }

    if (outletContext.clientEnv.enableBugsnag) {
      Bugsnag.setUser(outletContext.currentUser?.id, outletContext.currentUser?.email);
      Bugsnag.addMetadata('customer', outletContext.customer ?? {});
    }
  }, [outletContext]);
};

export const ErrorBoundary: ErrorBoundaryComponent = () => {
  return (
    <html lang='en'>
      <head>
        <GlobalMeta />
        <Meta />
        <Links />
      </head>
      <body className='bg-accent-10'>
        <ErrorBoundaryDisplay />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

};