import { useLocation, useNavigation, useRevalidator, useSubmit } from '@remix-run/react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '~/components/Button'
import { Dialog } from '~/components/Dialog'
import { useApplicationEventListener } from '~/routes/events.subscribe/route'
import { invariant } from '~/utils/invariant'

import { $path } from '../utils/route-helpers'

// Session length in milliseconds
export const MAX_SESSION_LENGTH = 24 * 60 * 60 * 1000

// Modal will be shown GRACE_PERIOD milliseconds before session expiration
const GRACE_PERIOD = 5 * 60 * 1000

// Check for session expiration every INTERVAL_LENGTH milliseconds
const INTERVAL_LENGTH = 15 * 1000

invariant(GRACE_PERIOD <= MAX_SESSION_LENGTH, 'Grace period must be less than max session length')
invariant(INTERVAL_LENGTH <= GRACE_PERIOD, 'Interval length must be less than grace period')

const isSessionExpired = (loginDate: string | undefined) => {
  if (!loginDate) return false

  return new Date(loginDate).getTime() < Date.now() - MAX_SESSION_LENGTH + GRACE_PERIOD
}

export const SessionExpired: React.FC<{ loginDate: string | undefined }> = ({ loginDate }) => {
  const { t } = useTranslation()
  const transition = useNavigation()
  const revalidator = useRevalidator()
  const submit = useSubmit()
  const location = useLocation()
  const signout = useCallback(
    (returnUrl?: string) => () => submit(returnUrl ? { returnUrl } : {}, { method: 'POST', action: $path('/auth/signout') }),
    [submit]
  )
  const [isModalOpen, setIsModalOpen] = useState(isSessionExpired(loginDate))

  // Revalidate when account changes, to close modal if the user re-authenticated in a different tab
  useApplicationEventListener(['accountChanged'], revalidator.revalidate)

  useEffect(() => {
    const intervalHandler = () => setIsModalOpen(isSessionExpired(loginDate))
    const interval = setInterval(intervalHandler, INTERVAL_LENGTH)

    return () => clearInterval(interval)
  }, [t, loginDate, isModalOpen])

  // Sign user out after grace period
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined

    if (isModalOpen) {
      timeout = setTimeout(signout(location.pathname), GRACE_PERIOD)
    } else {
      clearTimeout(timeout)
    }

    return () => clearTimeout(timeout)
  }, [isModalOpen, signout, location.pathname])

  return (
    <Dialog.Root open={isModalOpen}>
      <Dialog.Content maxWidth='xl'>
        <Dialog.Title>{t('sessionExpired.message')}</Dialog.Title>
        <div>
          <p className='mb-4'>{t('sessionExpired.details.line1')}</p>
          <p>{t('sessionExpired.details.line2')}</p>
        </div>
        <div className='mt-6 flex justify-end gap-2 '>
          <Button variant='text' color='primary' disabled={transition.state !== 'idle' || revalidator.state !== 'idle'} onClick={signout()}>
            {t('sessionExpired.cancelButton')}
          </Button>
          <Button
            variant='outlined'
            color='primary'
            disabled={transition.state !== 'idle' || revalidator.state !== 'idle'}
            onClick={signout(location.pathname)}
          >
            {t('sessionExpired.confirmButton')}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  )
}
