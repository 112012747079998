import { useLocation } from '@remix-run/react'
import { useEffect, useRef } from 'react'

import type { CurrentCustomer } from '~/root'
import * as gtag from '~/utils/gtags.client'

export const useGoogleAnalytics = (trackingId?: string, customer?: CurrentCustomer | null) => {
  const location = useLocation()
  const added = useRef(false)

  /**
   * Add <script> tags to the <head> to load Google Analytics.
   * Google analytics changes the <script> tags once they are loaded, so we're doing it this slightly
   * convoluted way to prevent hydration errors.
   * */
  useEffect(() => {
    if (added.current || !trackingId) {
      return
    }

    added.current = true
    const body = document.getElementsByTagName('body')[0]

    const gtagScript = document.createElement('script')
    gtagScript.async = true
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`

    const gtagExecutionScript = document.createElement('script')
    gtagExecutionScript.async = true
    gtagExecutionScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${trackingId}', {
        page_path: window.location.pathname,
      });
    `

    body.appendChild(gtagScript)
    body.appendChild(gtagExecutionScript)
  }, [trackingId])

  // Track Google Analytics pageviews
  useEffect(() => {
    if (trackingId) {
      gtag.pageview(location.pathname, trackingId)
    }
  }, [location, trackingId])

  useEffect(() => {
    if (trackingId && customer) {
      window.gtag('set', 'user_properties', {
        customer_name: customer.name,
        customer_id: customer.id
      })
    }
  }, [customer, trackingId])
}
