import { useNavigation, useSubmit } from '@remix-run/react'
import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIdleTimer } from 'react-idle-timer'

import { $path } from '~/utils/route-helpers'
import { formatCountdown } from '~/utils/time'

import { Button } from './Button'
import { Dialog } from './Dialog'

// how many milliseconds of inactivity before we sign the user out?
const timeout = 30 * 60 * 1000 // 30 minutes

// how many milliseconds before the user is signed out should we show them the prompt?
// at this point user activity in existing tabs will no longer reset the timer
// it will only reset if the user explictly agrees to stay signed in via button click OR they open a new tab
const promptBeforeIdle = 10 * 60 * 1000 // 10 minutes

export const InactivityTimeout: FC = () => {
  const { t } = useTranslation()
  const transition = useNavigation()
  const submit = useSubmit()
  const signout = useCallback(() => submit({}, { method: 'POST', action: $path('/auth/signout') }), [submit])

  const [remainingMs, setRemainingMs] = useState<number>(timeout)
  const [isPrompted, setIsPrompted] = useState<boolean>()

  const onPrompt = () => {
    // our effect will soon turn on to keep the countdown timer updated
    // but its initial value might be stale, so we'll update it once now to avoid the time "jumping" from the stale value to the new one
    setRemainingMs(getRemainingTime())
    setIsPrompted(true)
  }

  const onActive = () => {
    setIsPrompted(false)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle: signout,
    onPrompt,
    onActive,
    timeout,
    promptBeforeIdle,
    syncTimers: 1000,
    crossTab: true
  })

  useEffect(() => {
    if (!isPrompted) {
      return
    }

    const interval = setInterval(() => {
      const remainingMs = getRemainingTime()

      // this handles the edge case of a user opening a new tab when the prompt is already visible in multiple tabs
      // when that happens, the remaining time gets reset back to the full timeout value
      // but the onActive event is not fired, meaning the prompt is not automatically closed on tabs where it's already open
      // we handle that by checking if the time remaining is above promptBeforeIdle
      // if it is, we assume that means a new tab registered activity and fire the onActive event ourselves
      if (remainingMs > promptBeforeIdle) {
        onActive()
      }

      setRemainingMs(remainingMs)
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [activate, getRemainingTime, isPrompted])

  return (
    <Dialog.Root open={isPrompted}>
      <Dialog.Content maxWidth='xl'>
        <Dialog.Title>{t('inactivity.title')}</Dialog.Title>
        <div>{t('inactivity.message')}</div>
        <div className='mt-3 font-bold text-gray-700'>
          <span className='tabular-nums'>{formatCountdown(remainingMs)}</span> {t('inactivity.timeRemaining')}
        </div>
        <div className='mt-6 flex justify-end gap-2 '>
          <Button type='reset' variant='text' color='primary' onClick={signout} disabled={transition.state !== 'idle'}>
            {t('inactivity.logOut')}
          </Button>
          <Button variant='outlined' color='primary' onClick={activate} disabled={transition.state !== 'idle'}>
            {t('inactivity.staySignedIn')}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  )
}
