import { useEffect, useRef } from 'react'

const konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]

/**
 * A silly hook that adds a custom font to the page when the user enters the Konami Code.
 * The font is based on real US congressional district maps.
 *
 * Source: https://fontsarena.com/ugly-gerry/
 */
export const useKonamiCode = () => {
  const keysPressed = useRef<number[]>([])
  const fontAdded = useRef(false)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      keysPressed.current = [...keysPressed.current, event.keyCode].slice(-10)
      const konamiCodeEntered = keysPressed.current.join('') === konamiCode.join('')

      if (konamiCodeEntered && !fontAdded.current) {
        const style = document.createElement('style')
        style.innerHTML = `
          @font-face {
            font-family: "Gerry";
            src: url(/fonts/Gerry.otf) format("opentype");
          }
          .font-brand { font-family: 'Gerry' !important; }
        `
        document.head.appendChild(style)

        fontAdded.current = true
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
}
