import { useFetcher } from '@remix-run/react'
import { useEffect, useRef } from 'react'

import type { TimezoneLoaderData } from '~/routes/app.api.timezone/route'
import { TimezoneAction } from '~/routes/app.api.timezone/route'
import { FALLBACK_TIMEZONE } from '~/utils/time'

/**
 * This hook will automatically update the session's timezone if it's not set.
 * This is needed for propper SSR and time-related actions.
 * It will use the browser's timezone if it's supported, otherwise it will use the fallback timezone.
 **/
export const useAssertTimezoneIsSet = (currentTimezone: { name: string; isFallback: boolean }) => {
  const fetcher = useFetcher<TimezoneLoaderData>()
  const didUpdate = useRef(false)

  useEffect(() => {
    if (typeof window === 'undefined' || fetcher.state !== 'idle' || !currentTimezone.isFallback) return

    if (!fetcher.data?.supportedTimezones) {
      fetcher.load('/app/api/timezone')
      return
    }

    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const timezone = getBestMatchForBrowserTimezone(browserTimezone, fetcher.data.supportedTimezones)

    fetcher.submit(
      {
        action: TimezoneAction.update,
        timezone: timezone
      },
      {
        action: '/app/api/timezone',
        method: 'POST'
      }
    )
    didUpdate.current = true
  }, [currentTimezone.isFallback, fetcher])
}

const getBestMatchForBrowserTimezone = (browserTimezone: string, supportedTimezones: Awaited<TimezoneLoaderData>['supportedTimezones']) => {
  const exactMatch = supportedTimezones.find((timezone) => timezone.value === browserTimezone)

  if (exactMatch) return exactMatch.value

  const timezoneWithSameOffset = supportedTimezones.find((timezone) => {
    const date = new Date()

    // `getTimezoneOffset` returns positive numbers if behind UTC, negative if ahead.
    const offset = -(date.getTimezoneOffset() / 60)

    return timezone.offset === offset
  })

  if (timezoneWithSameOffset) return timezoneWithSameOffset.value

  return FALLBACK_TIMEZONE
}
