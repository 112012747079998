import { browserLogger } from '~/browser-logger'

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
const log = browserLogger()
export const pageview = (url: string, trackingId: string) => {
  if (!window.gtag) {
    log.warn('window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.')
    return
  }
  window.gtag('config', trackingId, {
    page_path: url,
    custom_map: { 'dimension<Index>': 'dimension_name' }
  })
}

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export const event = ({ action, category, label, value }: Record<string, string>) => {
  if (!window.gtag) {
    log.warn('window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.')
    return
  }
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  })
}
